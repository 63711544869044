import Vue from 'vue'
import './vue-sortable'

// define global variables
Vue.prototype.$editorSettings = {
  selector: 'textarea#classic',
  width: '100%',
  height: 750,
  resize: true,
  autosave_ask_before_unload: false,
  powerpaste_allow_local_images: true,
  plugins: [
    'a11ychecker advcode advlist anchor autolink codesample fullscreen help tinydrive',
    ' lists link noneditable powerpaste preview',
    ' searchreplace table template tinymcespellchecker visualblocks wordcount',
    'paste, table, advtable, help, wordcount, sourcecode, code, link, media, mediaembed, autolink',
  ],

  toolbar:
    'customPasteButton | undo redo | bold italic | h2 | forecolor backcolor | template codesample | alignleft aligncenter alignright alignjustify | bullist numlist | link | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
  menubar: 'file edit view insert format table tools help',
  spellchecker_dialog: true,
  spellchecker_ignore_list: ['Ephox', 'Moxiecode'],
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  setup: function(editor) {
    editor.ui.registry.addButton('customPasteButton', {
      icon: 'paste',
      tooltip: 'Paste Content',
      onAction: async function() {
        const clipboard = await navigator.clipboard.readText()
        editor.insertContent(clipboard)
      },
    })
  },
}

Vue.prototype.$editorApiKey = process.env.TINYMCE_KEY
