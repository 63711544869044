import Vue from 'vue'
import LoadScript from 'vue-plugin-load-script'
import Vue2TouchEvents from 'vue2-touch-events'
import lineClamp from 'vue-line-clamp'
import ElementUI from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import 'element-ui/lib/theme-chalk/index.css'
import { DataTablesServer } from 'vue-data-tables'

import App from './App.vue'
import router from './router'
import store from './store'
// Bugsnag
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import * as rules from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'

import VueCookies from 'vue-cookies'

// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

localize('en', en)

Vue.use(VueCookies, { domain: process.env.JUMPER_BASEDOMAIN })

// Install components globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

//getVersionFromPackageJson
import { version } from '../package.json'
Bugsnag.start({
  apiKey: '697128b648026dbdd3651d5d603c35cd',
  appVersion: version,
  plugins: [new BugsnagPluginVue()],
})

const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)
// Other libraries
Vue.use(Vue2TouchEvents)
Vue.use(lineClamp)
Vue.use(LoadScript)

//moment
Vue.use(require('vue-moment'))

// Element-UI and Data-Table
locale.use(lang)

Vue.use(ElementUI)
Vue.use(DataTablesServer)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
