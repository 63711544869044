





















import Vue from 'vue'
export default Vue.extend({
  name: 'DeleteConfirmationButton',
  props: {
    labelText: {
      type: String,
      default: 'Delete Content',
    },
    checkboxText: {
      type: String,
      default: 'I would like to delete this content',
    },
    helpText: {
      type: String,
      default: 'Clicking the delete button will permanently delete the content.',
    },
    buttonText: {
      type: String,
      default: 'Delete',
    },
  },
  data() {
    return {
      shouldDeleteContent: false,
    } as {
      shouldDeleteContent: boolean
    }
  },
})
