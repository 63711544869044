<template>
  <div class="page-wrapper">
    <slot />
  </div>
</template>

<script>
import Vue from 'vue'
import Header from '@/components/global/Header.vue'

export default Vue.extend({
  name: 'DefaultLayout',
  components: {
    Header,
  },
})
</script>
