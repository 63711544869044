import { ActionContext, ActionTree } from 'vuex'
import axios from '../../services/axios'
import { RootState } from '../types'
import { AuthState } from './types'

interface AuthActionContext extends ActionContext<AuthState, RootState> {}

export const actions: ActionTree<AuthState, RootState> = {
  async login({ commit }: AuthActionContext, payload) {
    try {
      const { data } = payload
      const headers = {
        Authorization:
          'Basic ' + Buffer.from(data.username + ':' + data.password).toString('base64'),
      }
      console.log(data)
      const res = await axios.post('/sign-in', null, { headers })
      const token = res.data

      localStorage.setItem('access_token', token)
      localStorage.setItem('email', data.username)
      commit('setIsAuthenticated', true)
    } catch (e) {
      throw e
    }
  },
  async forgotPassword({ commit }: AuthActionContext, payload) {
    try {
      console.log(payload)
      const { data } = await axios.post('users/reset-password', null, { params: payload })
    } catch (e) {
      throw e
    }
  },
  async resetPassword({ commit }: AuthActionContext, payload) {
    try {
      console.log(payload)
      const { data } = await axios.post('users/change-password', payload)
    } catch (e) {
      throw e
    }
  },
  logout() {
    localStorage.removeItem('access_token')
    localStorage.removeItem('email')
  },
  localAuthenticate({ commit }: AuthActionContext) {
    try {
      if (localStorage.getItem('email') === 'admin') {
        commit('setIsAuthenticated', true)
      }
    } catch (error) {
      commit('setIsAuthenticated', false)
    }
  },
  authenticateUser({ commit }: AuthActionContext) {
    try {
      console.log('auth this guy')
      localStorage.setItem('email', 'admin')
      commit('setIsAuthenticated', true)
    } catch (error) {
      console.log(error)
      commit('setIsAuthenticated', false)
    }
  },
}

export default actions
