









import Vue from 'vue'
import { mapState } from 'vuex'
import { version } from '../../../package.json'
export default Vue.extend({
  data() {
    return {
      displayUserName: localStorage.getItem('email') || '',
    }
  },
  computed: {
    ...mapState({
      isAuthenticated: (state: any) => state.auth.isAuthenticated,
    }),
    version() {
      return version
    },
  },
  methods: {
    logout(event) {
      event.preventDefault()
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
  },
  watch: {
    isAuthenticated() {
      this.displayUserName = localStorage.getItem('email') || ''
    },
  },
})
