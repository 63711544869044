<template>
  <div class="page-wrapper">
    <Header />
    <div class="content-wrapper">
      <Sidebar />
      <div class="property-content">
        <slot v-if="isReady" />
        <loading v-if="isLoadingData" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Loading from '@/components/global/Loading.vue'
import Header from '@/components/global/Header.vue'
import Sidebar from '@/components/global/Sidebar.vue'

export default Vue.extend({
  name: 'DefaultLayout',
  components: {
    Loading,
    Header,
    Sidebar,
  },
  data() {
    return {
      ready: false,
    }
  },
  computed: {
    isReady() {
      return this.$route.path === '/login' || this.ready
    },
    isLoadingData() {
      return this.$store.state.loading
    },
  },
  watch: {
    async '$route.path'(value, oldValue) {
      if (oldValue === '/login') {
        await this.init()
      }
    },
  },
  async mounted() {
    if (this.$route.path !== '/login') {
      await this.init()
    }
  },
  methods: {
    async init() {
      this.ready = true
    },
  },
})
</script>
<style scoped>
.content-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.property-content {
  width: calc(100% - 290px);
  margin-left: 290px;
}
.center {
  display: block;
  width: 20%;
  max-width: 200px;
  margin-right: auto;
  margin-left: auto;
}
</style>
