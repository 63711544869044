import { MutationTree } from 'vuex'
import { AuthState } from './types'

export const mutations: MutationTree<AuthState> = {
  setIsAuthenticated: (state, payload: boolean) => {
    state.isAuthenticated = payload
  },
  setAppReady: (state, payload: boolean) => {
    state.appReady = payload
  },
}
export default mutations
