import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import state from './state'
import { AuthState } from './types'
import { RootState } from '../types'

const namespaced: boolean = true

export const auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
}
