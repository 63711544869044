import Vue from 'vue'
import { Sortable, MultiDrag } from 'sortablejs'

Sortable.mount(new MultiDrag())

Vue.directive('sortable', {
  inserted(el, binding) {
    new Sortable(el, binding.value || {})
  },
})

Vue.mixin({
  methods: {
    sortableSelect(element) {
      Sortable.utils.select(element)
    },
    sortableDeselect(element) {
      Sortable.utils.deselect(element)
    },
  },
})
